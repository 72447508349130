import React from 'react'
import {MainLayout} from '../../layout'
import {ConfirmPhoneForm} from '../../components/auth'
import {confirmAccount, logIn, resendAccountConfirmationCode, sendEmailConfirmationCode} from '../../backend'
import {BooleanParam, StringParam, useQueryParam} from 'use-query-params'
import {navigate} from '../../utils'
import {useAuth} from '../../hooks'

export default function ConfirmPhone() {
  const {credentials, setUser} = useAuth()
  const [phone] = useQueryParam('phone', StringParam)
  const [hideSteps] = useQueryParam('hide_steps', BooleanParam)

  const handleAccountConfirmation = async ({code}) => {
    await confirmAccount({phone, code})
    const user = await logIn(credentials)
    await sendEmailConfirmationCode()
    setUser(user)
    navigate(`/auth/confirm-email?phone=${encodeURIComponent(phone)}`)
  }

  const handleResendCode = async () => {
    return resendAccountConfirmationCode({phone})
  }

  const handleConfirmedAlready = async () => {
    try {
      const user = await logIn(credentials)
      await sendEmailConfirmationCode()
      setUser(user)
      navigate(`/auth/confirm-email?phone=${encodeURIComponent(phone)}`)
    } catch (e) {
      console.log('error', e)
    }
  }

  if (!credentials) {
    navigate('/auth/login')
    return null
  }

  return (
    <MainLayout>
      <ConfirmPhoneForm onResendCode={handleResendCode}
                        onConfirm={handleAccountConfirmation}
                        onConfirmedAlready={handleConfirmedAlready}
                        hideSteps={hideSteps}/>
    </MainLayout>
  )
}
